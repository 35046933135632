
export const cartReducer=(state={cartItems:[], shippingAddress:{}}, action)=>{
    switch(action.type){
        case 'ADD_ITEM':
            const item = action.payload
            const existItem= state.cartItems.find(x => x.product === item.product)
if(existItem){
return{
    ...state,
    cartItems: state.cartItems.map(x => x.product === existItem.product ?  item : x)
}
}else{
return {
            ...state,
            cartItems:[...state.cartItems, item]
                }
}


            case 'REMOVE_ITEM':
 return {
    ...state,
    cartItems:state.cartItems.filter(x=>x.product !== action.payload)
 }

 case 'CART_SAVE_SHIPPING_ADDRESS':
 return {
    ...state,
    shippingAddress: action.payload,
 }


 case 'CART_SAVE_PAYMENT_METHOD':
 return {
    ...state,
    paymentMethod: action.payload,
 }

default:
 return state
    }
}



export const animateReducer=(state={}, action)=>{

    switch(action.type){
        case 'ADD_ITEM_ANIMATE':
            return {
                animation:action.payload
            }

            case 'ADD_ITEM_ANIMATE_RESET':
                return {}
        default:
            return state
 }
}
