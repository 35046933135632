import React, {useState, useEffect, Fragment} from 'react'
import{ Form, Col, Container} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import FormContainer from '../../components/FormContainer'
import { savePaymentMethod } from '../../actions/cartActions'
import CheckoutSteps from '../../components/ProgressBar/CheckoutSteps'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'


const PaymentScreen = () => {

const dispatch=useDispatch()
const history=useNavigate()

const cart = useSelector((state)=> state.cart)
const {shippingAddress} = cart
const {cartItems} = cart
const [paymentMethod, setPaymentMethod]=useState('PayPal')

const userLogin = useSelector((state)=> state.userLogin) 
const {userInfo}= userLogin
    

useEffect(()=>{

  if(shippingAddress.length===0 || cartItems.length===0){
    history('/shipping')
        }

},[userInfo, history, shippingAddress, cartItems])


    const formSubmitHandler=(event)=>{
        event.preventDefault()
        dispatch(savePaymentMethod(paymentMethod))
        history('/placeorder')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }


  return (
    <>
    <Header/>
    <Fragment>
    
    <Container className='formScreen formScreenNoMargin'>
    <div className='main'>
    <CheckoutSteps classname='onPayment'/>
  <FormContainer>
 
    <h1>Payment Method</h1>
    <Form as="form" className='form text-start' onSubmit={formSubmitHandler}>

    <Form.Group>
        <Form.Label as='legend'>Select Method</Form.Label>

    <Col required>

    <Form.Check
     type='radio'
     label='Debit/Credit Card or PayPal '
     name="PayPal"
     id='PayPal'
     value='PayPal'  
     checked={paymentMethod==='PayPal'}
     inline
     onChange={(event)=>setPaymentMethod(event.target.value)}
         ></Form.Check>

{/* <Form.Check
      type='radio'
      label='Cash On Delivery'
      name="Cash On Delivery"
      id='COD'
      value='Cash On Delivery'
      checked={paymentMethod==='Cash On Delivery'}
      onChange={(event)=>setPaymentMethod(event.target.value)}
         ></Form.Check> */}

{/* <Form.Check
     type='radio'
      label='Stripe'
      name="Stripe"
      id='Stripe'
      value='Stripe'
      checked={paymentMethod==='Stripe'}
      onChange={(event)=>setPaymentMethod(event.target.value)}
></Form.Check>  */}

    </Col>

    </Form.Group>
  <br></br>
  <button type='submit'>
  Continue
</button>

    </Form>

  </FormContainer>
  </div>
  </Container>
  </Fragment>
  <Footer/>
  </>
  )
}

export default PaymentScreen