import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Image, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckoutSteps from "../../components/ProgressBar/CheckoutSteps";
import { createOrder } from "../../actions/orderActions";
import { removeCartItems } from "../../actions/cartActions";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { paymentMethod } = cart;
  const { cartItems } = cart;
  const history = useNavigate();
  const [couponCode, setCouponCode] = useState();
  const [couponCodeState, setCouponCodeState] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState();
  const [orderItems, setOrderItems] = useState();
  const [calculateLoading, setCalculateLoading] = useState();
  const [calculateError, setCalculateError] = useState();

  const [error, setError] = useState(null);
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    const getCalculations = async () => {
      setCalculateLoading(true);
      setCalculateError();
      try {
        const response = await axios.post("/api/orders/calculate", {
          couponCode,
          cartItems,
        });
        cart.itemsPrice = response.data.itemsPrice;
        cart.totalPrice = response.data.totalPrice;
        cart.taxPrice = response.data.taxPrice;
        cart.shippingPrice = 0;
        setOrderItems(response.data.orderItems);
        setDiscountedPrice(response.data.discountedPrice);
        setCalculateError();
      } catch (error) {
        console.error("Failed Calculation: ", error);
        if (error.response && error.response.status === 404) {
          setCalculateError("Invalid Coupon code");
        }
      }
      setCalculateLoading(false);
    };
    getCalculations();
  }, [couponCode]);

  useEffect(() => {
    if (!paymentMethod || cartItems.length === 0) {
      history("/payment");
    }
  }, [history, dispatch, paymentMethod, cartItems]);

  const applyCoupon = () => {
    setCouponCode(couponCodeState);
  };

  const createOrder = async (data, actions) => {
    try {
      const response = await axios.post("/api/orders/payment/create-order", {
        amount: discountedPrice ? discountedPrice : cart.totalPrice,
      });

      const order = response.data;

      if (!order.orderID) {
        throw new Error("Order ID is missing");
      }
      return order.orderID;
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onApprove = async (data, actions) => {
    try {
      const orderID = data.orderID;
      const orderData = {
        user: userInfo && userInfo._id,
        orderID,
        orderItems,
        createdAt: Date().substring(0, 24),
        shippingAddress: shippingAddress,
        paymentMethod: paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        discountedPrice,
      };
      const response = await axios.post(
        "/api/orders/payment/capture-order",
        orderData
      );
      if (response.status === 200) {
        dispatch(removeCartItems());
        history(`/order/${response.data._id}`);
        window.location.reload(false);
      } else {
        console.error("Error creating order:", response.data);
        setError(response.data);
      }
    } catch (error) {
      console.error("Error capturing order:", error);
      setError("Error capturing order, retry by reloading the page.");
    }
  };

  return (
    <>
      <Header />
      <Fragment>
        <Container className="orderingScreens orderingScreensNoMargin">
          <div className="main">
            <CheckoutSteps classname="onPlaceOrder" />
            <Row>
              <Col lg={7}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Shipping</h2>
                    <Row>
                      <Col>
                        {" "}
                        <h6>Address</h6>{" "}
                        <p>
                          {" "}
                          {shippingAddress.address}, {shippingAddress.province},{" "}
                          {shippingAddress.city} {""}
                          {shippingAddress.postalCode}, {""}
                          {shippingAddress.country}
                        </p>
                      </Col>
                      <Col>
                        {" "}
                        <h6>Phone</h6> <p>{shippingAddress.phoneNum}</p>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Payment Method</h2>
                    <h6>Method</h6>
                    <p>{paymentMethod}</p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Order Items</h2>
                    {cartItems.length === 0 ? (
                      <h5>
                        Your Cart is empty!{" "}
                        <Link className="" to="/shop">
                          Shop Now
                        </Link>
                      </h5>
                    ) : (
                      <ListGroup variant="flush">
                        {orderItems &&
                          orderItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col className="mb-3" md={2}>
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    fluid
                                    rounded
                                  ></Image>
                                </Col>
                                <Col md={4}>
                                  <h6>Quantity:</h6> <p>{item.quantity}</p>
                                  <h6>Product Price:</h6>{" "}
                                  <p>
                                    $
                                    {item.sellPrice
                                      ? item.sellPrice
                                      : item.price}
                                  </p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col lg={5}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Order Summary</h2>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Items</h5>
                        </Col>
                        <Col>
                          <p>${cart.itemsPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Shipping</h5>
                        </Col>
                        <Col>
                          <p>${cart.shippingPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Tax HST</h5>
                        </Col>
                        <Col>
                          <p>${cart.taxPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Total Price</h5>
                        </Col>
                        <Col>
                          <p>${cart.totalPrice}</p>
                        </Col>
                      </Row>
                      {discountedPrice && (
                        <Row>
                          <Col>
                            <h5>Discounted Price</h5>
                          </Col>
                          <Col>
                            <p style={{ color: "green" }}>${discountedPrice}</p>
                          </Col>
                        </Row>
                      )}
                    </ListGroup.Item>
                    {error && (
                      <ListGroup.Item>
                        <p className="para_main" style={{ color: "red" }}>
                          {error}
                        </p>
                      </ListGroup.Item>
                    )}
                    {calculateError && (
                      <ListGroup.Item>
                        <p className="para_main" style={{ color: "red" }}>
                          {calculateError}
                        </p>
                      </ListGroup.Item>
                    )}
                    {loading || calculateLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <ListGroup.Item>
                          <div>
                            <p className="para_main pb-2">
                              Have a coupon code?
                            </p>
                            <div className="coupon">
                              <input
                                disabled={discountedPrice ? true : false}
                                type="text"
                                placeholder="Discount code"
                                value={couponCodeState}
                                onChange={(e) =>
                                  setCouponCodeState(e.target.value)
                                }
                              />
                              <button
                                disabled={discountedPrice ? true : false}
                                className="button_main"
                                onClick={applyCoupon}
                              >
                                Apply Coupon
                              </button>
                            </div>
                          </div>
                        </ListGroup.Item>
                        {loading ? (
                          <Loader />
                        ) : (
                          <ListGroup.Item>
                            <PayPalButton
                              currency={"CAD"}
                              options={{
                                clientId:
                                  "AQ2CWBf40_qcVy_mET_scvqAOPKmhdso-pdxAGC7YSwGZ9mbPqa9IaaCn3gGnDMv_BFerSLUmhWvIIaV",
                                currency: "CAD",
                              }}
                              shippingPreference="NO_SHIPPING"
                              createOrder={createOrder}
                              onApprove={onApprove}
                            />
                          </ListGroup.Item>
                        )}
                      </>
                    )}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
      <Footer />
    </>
  );
};

export default PlaceOrderScreen;
