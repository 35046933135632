import axios from "axios";
import emailjs from "@emailjs/browser";

export const createOrder = (order) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "ORDER_CREATE_REQUEST",
      });

      const { data } = await axios.post(`/api/orders`, order);

      dispatch({
        type: "ORDER_CREATE_SUCCESS", // Remove it when using email js
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_CREATE_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const getOrderDetails = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "ORDER_DETAILS_REQUEST",
      });
      const { data } = await axios.get(`/api/orders/${id}`);

      dispatch({
        type: "ORDER_DETAILS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_DETAILS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const payOrder = (orderId, paymentResult) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "ORDER_PAY_REQUEST",
      });
      const { data } = await axios.put(
        `/api/orders/${orderId}/pay`,
        paymentResult
      );
      dispatch({
        type: "ORDER_PAY_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_PAY_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const deliverOrder = (order) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "ORDER_DELIVER_REQUEST",
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${order._id}/deliver`,
        {},
        config
      );

      dispatch({
        type: "ORDER_DELIVER_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_DELIVER_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const myOrdersListAction = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "MY_ORDER_LIST_REQUEST",
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/orders/myorders`, config);

      dispatch({
        type: "MY_ORDER_LIST_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "MY_ORDER_LIST_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};

export const listOrders = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "ORDER_LIST_REQUEST",
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/orders`, config);

      dispatch({
        type: "ORDER_LIST_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_LIST_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
};
