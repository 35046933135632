import React, { useEffect, useState } from "react";
import styles from "./BlogSec.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

let defaultBlogObj = [];

const BlogSec = () => {
  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blog", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/images/logo.png",
              entity: "Tru Off Grid",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  return (
    <div className={styles.main}>
      <Container>
        {loading ? (
          <div className="w-100 text-center mb-5">
            <Spinner
              style={{ color: "red", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          <Row className="gy-4 gx-lg-5">
            <Col lg={7}>
              <div className={styles.boxCol}>
                {blogs.map((item) => {
                  return (
                    <div className={styles.mainBox}>
                      <h1 className="h1_main">{item.main_heading}</h1>
                      <div className={styles.head}>
                        <div className={styles.info}>
                          <img src="/images/logo_footer.png" />
                          <div>
                            <h2 className="h2_main pb-2">Tru Off Grid</h2>
                            <Row>
                              <p
                                style={{ width: "auto" }}
                                className="para_main pe-2"
                              >
                                {item.date}
                              </p>
                              <p
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  width: "auto",
                                }}
                                className="para_main"
                              >
                                latest
                              </p>
                            </Row>
                          </div>
                        </div>
                        <button
                          onClick={() => redirectUserToBlog(item.slug_url)}
                          className="button_main"
                        >
                          Read more
                        </button>
                      </div>
                      <img
                        onClick={() => redirectUserToBlog(item.slug_url)}
                        className={styles.img}
                        src={item.blog_creative}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: item.paragraph }}
                        style={{ color: "white", textAlign: "start" }}
                        className="para_main"
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col lg={5}>
              <div className={styles.contentCol}>
                <h1 className="h1_main pb-3">Most Popular</h1>
                {recentBlogs.map((item) => {
                  return (
                    <div
                      onClick={() => redirectUserToBlog(item.slug_url)}
                      className={styles.contentBox}
                    >
                      <img src={item.logo} />
                      <div>
                        <h4 className="h4_main pb-2">
                          {item.blog_description}
                        </h4>
                        <p style={{ width: "auto" }} className="para_main pe-2">
                          {item.date}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BlogSec;
