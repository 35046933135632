import React, {useEffect, useState} from 'react'
import{Form, Container} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate} from 'react-router-dom'
import Message  from '../../components/Message'
import Loader from '../../components/Loader'
import {getUserDetails, updateUserProfile} from '../../actions/userActions'
import FormContainer from '../../components/FormContainer'
import PhoneNumber from '../../components/PhoneInput/PhoneNumber'
import Footer from '../../components/Footer_/Footer'
import Header from '../../components/Header_/Header'



const ProfileScreen=()=>{

    const [FirstName, setFirstName]= useState('')
    const [LastName, setLastName]= useState('')
    const [Email, setEmail]= useState('')
    const [PhoneNum, setPhoneNum] = useState('')
    const [password, setPassword]= useState('')
    const [confirmPassword, setConfirmPassword]= useState('')
    const [checked, setChecked]= useState(false)
    const [message, setMessage]= useState(null)
    const[ successMessage, setSuccessMessage]=useState(null)
    const [displayPassword, setDisplayPassword]= useState(false)

    const dispatch = useDispatch()
    const history = useNavigate()

    const userLogin = useSelector((state)=> state.userLogin) 
    const {userInfo}= userLogin

    const userDetails = useSelector((state)=> state.userDetails) 
    const {_id, firstName, lastName, email, phoneNum, error, loading} = userDetails

    const userUpdateProfile = useSelector((state)=> state.userUpdateProfile) 
    const {success, error:updateProfileError}= userUpdateProfile

 
   useEffect(()=>{

if(!userInfo){
            history('/login')
        }
 else{

   if(!firstName || success){
     dispatch({type:'USER_UPDATE_PROFILE_RESET'})
     dispatch(getUserDetails('profile'))
     }
  else{ 
    dispatch({type:'ORDER_DETAILS_RESET'})   
    setFirstName(firstName)
    setLastName(lastName)
    setEmail(email)
    setPhoneNum(phoneNum)
    }
 }

}, [firstName, lastName, email, phoneNum, dispatch, history, userInfo, success])


 
const formSubmitHander=(event)=>{
    event.preventDefault()
    if(password !== confirmPassword){
        setMessage('Passwords do not match')
        setSuccessMessage(null)
    }
  else {
           if(password.length < 6 && password.length > 0){
           setMessage('Password should be equal or more than six characters')
           setSuccessMessage(null)
         }
    else{
      dispatch(updateUserProfile({_id:_id, firstName:FirstName, lastName:LastName, email:Email, phoneNum:PhoneNum, password:password}))
      setSuccessMessage(true)
      setMessage(null)
      setChecked(false)
     }
    }
 }

 const updateProfileHandler=()=>{
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
 }

 const formOnChange=()=>{
 setChecked(true)
 }

const callback = (phoneNumber) => {
  setPhoneNum(phoneNumber)
  setChecked(true)
}


const showPassword=()=>{
  setDisplayPassword(!displayPassword)
}

  return (
    <>
    <Header/>
    <React.Fragment>
<Container className="formScreen pt-5 pb-5">
    <FormContainer>
       <h1>User Profile</h1>
       {message && !successMessage &&<Message variant='danger'>{message}</Message>}
       {!loading ? (successMessage && <Message variant='success'>Profile Updated Successfully</Message>):''}
       {error && <Message variant='danger'>{error}</Message>}
       {updateProfileError && <Message variant='danger'>{'User Already Exists'}</Message>}
       {loading && <Loader />}

    <Form as="form" className='form text-center' onSubmit={formSubmitHander} onChange={formOnChange}>

    <Form.Group className='mb-3 text-start' controlId='firstName'>
        <Form.Label>First Name</Form.Label>
        <Form.Control maxLength="12" type='text' placeholder='Enter First Name' pattern=".{3,}" required value={FirstName}
         onChange={(event)=>setFirstName(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='lastName'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control maxLength="12" type='text' placeholder='Enter Last Name' pattern=".{3,}" required value={LastName}
         onChange={(event)=>setLastName(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='email'>
        <Form.Label>Email Address</Form.Label>
        <Form.Control type='email' placeholder='Enter Email' pattern=".{3,}" maxLength="36" required value={Email}
         onChange={(event)=>setEmail(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start phoneInput'>
        <Form.Label>Phone Number</Form.Label>
        <PhoneNumber value={PhoneNum} onChange={callback}/>
      </Form.Group>

      <div className='reText '>
        <h1>Reset Password</h1>
      </div>

      <Form.Group className='mb-3 text-start' controlId='password'>
        <Form.Label>New Password</Form.Label>
        <Form.Control type='password' placeholder='Enter New Password' maxLength="16" value={password}
         onChange={(event)=>setPassword(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='confirmPassword'>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control  type={!displayPassword ? 'password':'text'} placeholder='Confirm Password' maxLength="16" value={confirmPassword}
         onChange={(event)=>setConfirmPassword(event.target.value)}></Form.Control>
         <p className='text-end'>{!displayPassword ? 'Show':'Hide'}  <i className={!displayPassword ? 'fa-solid fa-eye':'fa-solid fa-eye-slash'} onClick={showPassword}></i></p> 
      </Form.Group>

      <button type='submit' onClick={updateProfileHandler} disabled={!checked}>Update Profile</button> 
    </Form>
</FormContainer> 
</Container>
</React.Fragment>
<Footer/>
</>
  )
  }

export default ProfileScreen