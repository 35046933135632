import React, { Fragment, useEffect } from "react";
import { addToCart } from "../../actions/cartActions";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, ListGroup, Row, Image, Card, Container } from "react-bootstrap";
import { removeFromCart } from "../../actions/cartActions";
import Header from "../../components/Header_/Header";
import Footer from "../../components/Footer_/Footer";

const CartScreen = () => {
  const params = useParams();
  const productId = params.id;
  const [searchParams] = useSearchParams();
  const quantity = Number(searchParams.get("quantity"));
  const dispatch = useDispatch();
  const history = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, quantity));
    }
  }, [dispatch, productId, quantity]);

  const removeFromCartHandler = (productId) => {
    dispatch(removeFromCart(productId));
    history("/cart");
  };

  const productQty = cartItems.map((idv) => idv.quantity);
  const ttlQty = productQty.reduce(qtySum, 0);
  function qtySum(total, num) {
    return total + Math.round(num);
  }

  const ttlPrice = cartItems.reduce(
    (total, item) =>
      total + (item.sellPrice ? item.sellPrice : item.price) * item.quantity,
    0
  );

  const checkoutHandler = () => {
    // localStorage.removeItem("Guest");
    // if (!userInfo) {
    //   history("/login?redirect=shipping");
    //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // } else {
    //   if (userInfo) {
        history("/shipping");
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    //   }
    // }
  };

  return (
    <>
      <Header />
      <Fragment>
        <Container className="orderingScreens">
          <div className="main">
            <Row>
              <Col md={8} lg={8}>
                <ListGroup variant="flush">
                  <h1 className="text-start ms-3">Cart</h1>
                  {cartItems.length === 0 && (
                    <h4 className="text-start ms-3 mb-4 emptyCartLink">
                      Your Cart Is Empty!{" "}
                      <Link className="" to="/shop">
                        Shop Now
                      </Link>
                    </h4>
                  )}
                  {cartItems.map((item) => (
                    <ListGroup.Item key={item.product}>
                      <Row>
                        <Col className="mb-3" md={3}>
                          <Link to={`/product/${item.product}`}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Link>
                        </Col>
                        <Col md={5}>
                          <h4>{item.name}</h4>
                          <h6 style={{ margin: "0" }}>Quantity: </h6>
                          <p>{item.quantity}</p>
                        </Col>
                        <Col md={2}>
                          <h6 style={{ margin: "0" }}>
                            ${item.price}
                          </h6>
                        </Col>
                        <Col className="mb-3" md={2}>
                          <button
                            type="button"
                            className="trashIcon"
                            variant="light"
                            onClick={() => removeFromCartHandler(item.product)}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col md={4} lg={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Total ({ttlQty}) items</h2>{" "}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Subtotal</h5>
                        </Col>
                        <Col>
                          <p>${ttlPrice.toFixed(2)}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <button
                        type="button"
                        className="btn-Block"
                        disabled={cartItems.length === 0}
                        onClick={checkoutHandler}
                      >
                        Proceed To Checkout
                      </button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
      <Footer />
    </>
  );
};

export default CartScreen;
