import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listProductDetails,
  updateProduct,
} from "../../actions/productActions";
import FileBase64 from "react-file-base64";
import FormContainer from "../../components/FormContainer";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import axios from "axios";

const ProductEditScreen = () => {
  const params = useParams();
  const productId = params.id;

  const [url, set_url] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [category, setCategory] = useState("");
  const [recommended, setRecommended] = useState(null);
  const [description, setDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [canonical, setCanonical] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [products, setProducts] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "PRODUCT_UPDATE_RESET" });

    if (!userInfo || !userInfo.isAdmin) {
      history("/login");
    }
    if (successUpdate) {
      history("/admin/productlist");
      window.location.reload();
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        set_url(product.url);
        setName(product.name);
        setPrice(product.price);
        setSellPrice(product.sellPrice);
        setDescription(product.description);
        setMetaTitle(product.metaTitle);
        setMetaDescription(product.metaDescription);
        setMetaKeyword(product.metaKeyword);
        setCanonical(product.canonical);
        setImage(product.image);
        setImage2(product.image2);
        setImage3(product.image3);
        setImage4(product.image4);
        setImage5(product.image5);
        setImage6(product.image6);
        setCategory(product.category);
        setRecommended(product.recommended);
        setRecommendedProducts(product.recommendedProducts);
        setCountInStock(product.countInStock);
      }
    }
  }, [dispatch, history, product, productId, successUpdate, userInfo]);

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await axios.get(`/api/products`);
      setProducts(data.filter((product) => product._id !== productId));
    };
    fetchProducts();
  }, []);

  const handleCheckboxChange = (productId) => {
    if (recommendedProducts.includes(productId)) {
      setRecommendedProducts(
        recommendedProducts.filter((id) => id !== productId)
      );
    } else {
      setRecommendedProducts([...recommendedProducts, productId]);
    }
  };

  const uploadImg1Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg2Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage2(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg3Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage3(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg4Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage4(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg5Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage5(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImg6Handler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = { Headers: { "Content-Type": "multipart/form-data" } };
      const { data } = await axios.post("/api/upload", formData, config);
      setImage6(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const formSubmitHander = (event) => {
    event.preventDefault();
    dispatch(
      updateProduct(productId, {
        url,
        name,
        image,
        image2,
        image3,
        image4,
        image5,
        image6,
        description,
        metaTitle,
        metaDescription,
        metaKeyword,
        canonical,
        price,
        sellPrice,
        category,
        recommended,
        countInStock,
        recommendedProducts,
      })
    );
  };

  return (
    <>
      <Header />
      <Fragment>
        <div className="main">
          <Container>
            <Link to="/admin/productlist" className="btn btn-dark my-3">
              Go Back
            </Link>
            <FormContainer>
              <h1>Edit Product</h1>
              {loadingUpdate && <Loader />}
              {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : (
                <Form onSubmit={formSubmitHander}>
                  <Form.Group className="mb-3" controlId="id">
                    <Form.Label>Unique URL</Form.Label>
                    <Form.Control
                      type="id"
                      placeholder="Enter Unique URL"
                      value={url}
                      onChange={(event) => set_url(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Price"
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Sale Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Price"
                      value={sellPrice}
                      onChange={(event) => setSellPrice(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg1Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Second Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image2}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg2Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Third Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image3}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg3Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Fourth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image4}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg4Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Fifth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image5}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg5Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="image">
                    <Form.Label>Sixth Image</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Image URL"
                      value={image6}
                    ></Form.Control>
                    <Form.Control
                      type="file"
                      id="image-file"
                      label="Choose File"
                      custom
                      onChange={uploadImg6Handler}
                    ></Form.Control>
                    {uploading && <Spinner />}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Enter Description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="title">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Meta Title"
                      value={metaTitle}
                      onChange={(event) => setMetaTitle(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder="Enter Meta Description"
                      value={metaDescription}
                      onChange={(event) =>
                        setMetaDescription(event.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="canonical">
                    <Form.Label>Canonical</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Canonical"
                      value={canonical}
                      onChange={(event) => setCanonical(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="keyword">
                    <Form.Label>Meta Keywords</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Meta Keyword"
                      value={metaKeyword}
                      onChange={(event) => setMetaKeyword(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="category">
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      type="text"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="12V Batteries">12V Batteries</option>
                      <option value="24V Batteries">24V Batteries</option>
                      <option value="36V Batteries">36V Batteries</option>
                      <option value="48V Batteries">48V Batteries</option>
                      <option value="Starting Batteries">
                        Starting Batteries
                      </option>
                      <option value="Solar Batteries">Solar Batteries</option>
                      <option value="Ecoflow Powerstations">
                        Ecoflow Powerstations
                      </option>
                      <option value="EG4 Batteries and Inverters">
                        EG4 Batteries and Inverters
                      </option>
                      <option value="EcoFlow Home Back Up">
                        EcoFlow Home Back Up
                      </option>
                      <option value="Portable Solar Generators">
                        Portable Solar Generators
                      </option>
                      <option value="Ecoflow Portable Solar Panels">
                        Ecoflow Portable Solar Panels
                      </option>
                      <option value="Rigid Solar Panels">
                        Rigid Solar Panels
                      </option>
                      <option value="Off Grid Solar Systems">
                        Off Grid Solar Systems
                      </option>
                      <option value="Residential Solar Systems">
                        Residential Solar Systems
                      </option>
                      <option value="Chargers And Accessories">
                        Chargers And Accessories
                      </option>
                      <option value="uncategorized">uncategorized</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="countInStock">
                    <Form.Label>Count In Stock</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Set CountInStock "
                      value={countInStock}
                      onChange={(event) => setCountInStock(event.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="colour2">
                    <Form.Label>Featured Product</Form.Label>
                    <Form.Check
                      checked={recommended === true}
                      label="Featured"
                      onChange={(e) =>
                        e.target.checked
                          ? setRecommended(true)
                          : setRecommended(false)
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="colour2">
                    <Form.Label>Select Recommended Products</Form.Label>
                    <div className="d-flex justify-content-center flex-wrap gap-5 flex-row mt-3">
                      {products.map((product) => (
                        <div style={{ width: "120px" }} key={product._id}>
                          <label
                            className="d-flex justify-content-center flex-column"
                            htmlFor={product._id}
                          >
                            <img
                              src={product.image}
                              style={{
                                width: "100%",
                                height: "110px",
                              }}
                            />
                            {product.name.split(" ").slice(0, 3).join(" ")}
                          </label>
                          <input
                            className="pt-3"
                            type="checkbox"
                            id={product._id}
                            checked={recommendedProducts.includes(product._id)}
                            onChange={() => handleCheckboxChange(product._id)}
                          />
                        </div>
                      ))}
                    </div>
                  </Form.Group>

                  <br></br>
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Form>
              )}
            </FormContainer>
          </Container>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default ProductEditScreen;
