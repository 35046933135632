import React from "react";
import styles from "./Reviews.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import "./Reviews.css";
import { Autoplay, Navigation, Pagination } from "swiper";

const Reviews = () => {
  return (
    <div className={styles.main}>
      <Container>
        <Row className="gy-5 gx-lg-5">
          <Col lg={5}>
            <div className={styles.contentCol}>
              <h1 className="h1_main pb-3">
                What Our <br />
                <span>Customer's</span> Say
              </h1>
              <p className="para_main pb-4">
                Check out what our customers have said about our level of
                customer service and solutions-oriented focus. Thank you for
                your time!
              </p>
              <a
                target="no_blank"
                href="https://maps.app.goo.gl/sPfiQnwCVcYNq6z46"
              >
                <button className="button_main">Read more</button>
              </a>
            </div>
          </Col>
          <Col lg={7}>
            <div className={styles.boxCol}>
              <Swiper
                loop
                spaceBetween={50}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  280: {
                    slidesPerView: 1,
                  },
                }}
                modules={[Navigation, Autoplay, Pagination]}
                className="testimonials_swiper"
              >
                <SwiperSlide>
                  <div className={styles.box}>
                    <img
                      alt=""
                      className={styles.image}
                      src="/images/testimonial5.png"
                    />
                    <h2 className="h2_main">Ian Ducharme</h2>
                    <img alt="" src={"/images/reviews.svg"} />
                    <p className="para_main">
                      Looking for all things solar, lithium and power management
                      in the GTA? Look no more. Jack has the best prices and
                      he's a quick drive away West of Toronto in the beautiful
                      village of Milton. Save costly duty and delivery fees. The
                      prices are amazing and even compare to Amazon. Jack is
                      friendly, courteous, patient and gives you service with a
                      smile. I highly recommend this business. 5 ⭐⭐⭐⭐⭐
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img
                      alt=""
                      className={styles.image}
                      src="/images/testimonial1.png"
                    />
                    <h2 className="h2_main">Artemio Delos Trinos</h2>
                    <img alt="" src={"/images/reviews.svg"} />
                    <p className="para_main">
                      Jack is super easy to work with, great service and pricing
                      as well. I've been in "the space" for several years,
                      worked with many different suppliers, TRU Off Grid has
                      been the friendliest and most reliable.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img
                      alt=""
                      className={styles.image}
                      src="/images/testimonial2.png"
                    />
                    <h2 className="h2_main">Mike Moffatt</h2>
                    <img alt="" src={"/images/reviews.svg"} />
                    <p className="para_main">
                      Received my Delta 2 max ...hadn't met either Jon or Jack
                      but felt confident my order would go smoothly and it went
                      off without a problem. Pricing was competitive and
                      delivery professional. All good Thanks TRU Off Grid
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img
                      alt=""
                      className={styles.image}
                      src="/images/testimonial3.png"
                    />
                    <h2 className="h2_main">Ethan Kennedy</h2>
                    <img alt="" src={"/images/reviews.svg"} />
                    <p className="para_main">
                      I was utterly shocked by the service and the quick
                      response time from Tru Off Grid. My panels are up and
                      working and it’s really helped cut down on the electrical
                      bill. Highly recommend.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.box}>
                    <img
                      alt=""
                      className={styles.image}
                      src="/images/testimonial4.png"
                    />
                    <h2 className="h2_main">Michael Kery</h2>
                    <img alt="" src={"/images/reviews.svg"} />
                    <p className="para_main">
                      Picked up a EcoFlow Delta 2 from TRU... Fast, friendly and
                      knowledgeable service... will be back.
                      <br />
                      <br />
                      Update - I was able to work remotely in the forest for a
                      week thanks to the great recommendations of TRU... sharing
                      a photo of setup!
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reviews;
