import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4"
          >{`Warranty And Return`}</h2>
          <p className="para_main">
            Our TRU Off Grid Policies for Free Shipping, Product Returns,
            Refunds and Special Orders
            <br />
            <br />
            Free Shipping on ALL Products and Canada Wide!
            <br />
            <br />
            We Offer Free Shipping Canada Wide on ALL Lithium Batteries and
            ECOFLOW Products. Please call Jack Abrams about Shipping at 905 299
            7841 if you have questions. We ship with FedEx, UPS and Purolator
            and once picked up by the courier usually gets to you in 2-3 Days.
            We process orders through the weekend and will provide you with your
            tracking number on request.
            <br />
            <br />
            120 Day Product Return Policy
            <br />
            <br />
            We accept product returns for any reason up to 120 days from product
            delivery date to the customer. Product must be returned in perfect,
            new condition and will be inspected prior to customer refund.
            <br />
            <br />
            For manufacturers defects under warranty there will be no cost to
            customer for the return or replacement. We will provide a return
            shipping label and the product will be replaced at no charge by the
            manufacturer.
            <br />
            <br />
            For a product return for reasons other than manufacturer defects,
            the customer is responsible for the return cost and there will be a
            5% restocking fee.
            <br />
            <br />
            Special Order Products and Out of Stock or Inbound Delay Situations:
            <br />
            <br />
            Out of Stock and Product Order Policy: The vast majority of the
            batteries, power stations and solar panels are regularly stocked at
            our shop in Milton, Ontario. If by chance we are out of stock, we
            can usually be fulfilled by our suppliers within 2-14 days. Should
            you purchase a product that is incoming on an order from one of our
            suppliers or that we need to order, our policy is that we have 14
            days from date of purchase to supply the product you have purchased
            prior to a refund if a refund is requested. Should a customer cancel
            an order due to an out of stock situation, we will deduct 3% for
            Paypal or Square transaction fees for all order cancellations. There
            are some products that we showcase on our website that are
            considered special order. An example is the 12V 560AH Dakota Lithium
            Battery and large 48V batteries. If we order a product for a
            customer and the customer cancels the order, there will be a 5%
            restocking fee and any Paypal or Square Credit Card Transaction fees
            of 3% will also be deducted from the refund. Should you have any
            questions about out inventory on hand, please call Jack Abrams at
            905 299 7841 or email sales@truoffgrid.com. Thank you:)
            <br />
            <br />
            <br />
            Dakota Lithium:
            <br />
            <br />
            Dakota Lithium offers an Industry Leading 11 Year Warranty Program
            for a FULL 11 Years from Manufacturer Defects and not Pro-Rated.
            They truly stand behind their product and brand which is one of the
            major reasons we distribute their LiFePO4 deep cycle batteries.
            <br />
            <br />
            For any issues with your Dakota Lithium Battery please contact
            support@dakotalithium.com and copy sales@truoffgrid.com so we are in
            the loop.
            <br />
            <br />
            All Warranty or Battery Performance issues are handled directly by
            Dakota Lithium US technical support department. They are extremely
            helpful and will assist you in trouble shooting your battery in the
            unlikely event of an issue. You will need your proof of purchase and
            serial number.
            <br />
            <br />
            This warranty does not cover negligence or misuse or modifications
            of the battery or normal wear and tear. If it is deemed that the
            battery was used improperly, you will be subject to a $150 an hour
            repair charge plus parts and shipping.
            <br />
            <br />
            Dakota Lithium 12V to 48V batteries are not intended or warrantied
            for serial wiring applications above 48V.
            <br />
            <br />
            <br />
            ECOFLOW offers a 5 Year Warranty: Please contact
            sales@truoffgrid.com and we will support you. ECOFLOW has always
            been amazing in supporting product performance issue. These issues
            are rare but they can happen.
            <br />
            <br />
            How to claim your warranty?
            <br />
            <br />
            Step 1
            <br />
            <br />
            Claim your warranty on ecolfow.com :
            https://ecoflow.com/apps/product-registration
            <br />
            <br />
            (Find Your Product’s Serial Number:
            https://ecoflow.com/apps/product-registration?serialNumber=true)
            <br />
            <br />
            Step 2
            <br />
            <br />
            Contact TRU Off Grid or Contact EcoFlow Customer Service Team at
            support@ecoflow.com or +1 (800)-368-8604 and describe the issue with
            your unit. EcoFlow will respond with an email asking specific
            questions about your unit and the serial number of your unit. They
            will also request a short video to show the issues.
            <br />
            <br />
            Step 3
            <br />
            <br />
            While the Customer Service Representative processes the claim, you
            will receive a Return Material Authorization (RMA) form.
            <br />
            <br />
            If your item is eligible for EcoFlow Warranty Policy, you will
            received a prepaid shipping label*. Please follow the instructions
            on how to properly package and return the item safely. Please follow
            the instructions above before you ship out the products.
            <br />
            <br />
            *Please note that EcoFlow will only provide prepaid return shipping
            labels for return packages in the U.S., Canada and Europe. For
            return in other countries and areas, please contact our Customer
            Service Team at support@ecoflow.com for more details.
            <br />
            <br />
            **Instruction on how to properly package and return the item safely.
            <br />
            <br />
            Step 4
            <br />
            <br />
            EcoFlow will proceed with replacement 15 business days* after
            confirming that the defective item is received at our warehouse. The
            final resolution is subject to the availability of the replacement
            items.
            <br />
            <br />
            All replacement items will follow the same warranty time frame of
            the original defective item or 3 months after being replaced,
            whichever is later.
            <br />
            <br />
            *Please note that delivery is subject to changes caused by force
            majeure.
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;
