import thunk from 'redux-thunk'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import { productListReducer, productDetailsReducer, productDeleteReducer, productCreateReducer, productUpdateReducer, productQueryReducer } from '../reducers/productReducers'
import {animateReducer, cartReducer} from '../reducers/cartReducers'
import { myOrdersListReducer, orderCreateReducer, orderDeliverReducer, orderDetailsReducer, orderPayReducer, ordersListReducer } from '../reducers/orderReducers'
import { userAccountRecoveryCodeReducer, userDeleteReducer, userDetailsReducer, userGoogleLoginReducer, userListReducer, userLoginReducer, userRecoveryLoginReducer, userRegisterReducer, userUpdateProfileReducer, userUpdateReducer } from '../reducers/userReducers'


const reducer= combineReducers({
    productList: productListReducer,
    productQuery:productQueryReducer,
    productDetails: productDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    cart: cartReducer,
    userLogin: userLoginReducer,
    userAccountRecoveryCode: userAccountRecoveryCodeReducer,
    userRecoveryLogin: userRecoveryLoginReducer,
    userGoogleLogin: userGoogleLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userList:userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userUpdateProfile: userUpdateProfileReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderDeliver: orderDeliverReducer,
    myOrdersList: myOrdersListReducer,
    orderList: ordersListReducer,
    animate:animateReducer,
})

const cartItemsFromStorage= localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []
const userInfoFromStorage= localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
const shippingAddressFromStorage= localStorage.getItem('shippingAddress') ? JSON.parse(localStorage.getItem('shippingAddress')) : []


const initialState={
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        },
    userLogin: {userInfo: userInfoFromStorage}

     
}

const middleware=[thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware) ))

export default store