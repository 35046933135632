import React,{useState, useEffect} from 'react'
import{Row, Col, Form, Container} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {register} from '../../actions/userActions'
import FormContainer from '../../components/FormContainer'
import emailjs from '@emailjs/browser';
import PhoneNumber from '../../components/PhoneInput/PhoneNumber'


const RegisterScreen = () => {

    const [firstName, setFirstName]= useState('')
    const [lastName, setLastName]= useState('')
    const [email, setEmail]= useState('')
    const [PhoneNum, setPhoneNum] = useState('')
    const [password, setPassword]= useState('')
    const [confirmPassword, setConfirmPassword]= useState('')
    const [message, setMessage]= useState(null)
    const [displayPassword, setDisplayPassword]= useState(false)

    const userRegister = useSelector((state)=> state.userRegister) 
    const {userInfo, error, loading}= userRegister

    const userLogin = useSelector((state)=> state.userLogin) 
    const {userInfo:userLoginInfo}= userLogin

    const dispatch = useDispatch()
    const history = useNavigate()

    const [searchParams] = useSearchParams();
    const redirect = searchParams.get('redirect')

    // const requestData = localStorage.getItem('googleUserData')
    // const googleData = JSON.parse(requestData)

     const userGoogleLogin = useSelector((state)=> state.userGoogleLogin) 
     const {loading:loadingGoogleLogin, success}= userGoogleLogin
    
useEffect(()=>{

  if(userInfo || userLoginInfo || success){ 
    history('/home')
}

  if(userInfo && redirect){
      history(`/${redirect}`)
  }
//   else{
//   if(googleData){
//     setFirstName(googleData.firstName)
//     setLastName(googleData.lastName)
//     setEmail(googleData.email)
// }
//   }
  // eslint-disable-next-line 
 },[ history, userInfo, redirect,  success, userLoginInfo]) // requestData,

const showPassword=()=>{
  setDisplayPassword(!displayPassword)
}

const signInHandler=()=>{
  localStorage.removeItem('googleUserData')
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
}

    const formSubmitHander=(event)=>{
      event.preventDefault()
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      if(password !== confirmPassword){
          setMessage('Passwords do not match')
      }
    else{
      if(password.length===0 || email.length===0 || firstName.length===0 || lastName.length===0 || confirmPassword.length===0){
        setMessage('Please Fill The Form')
      }
      if(password.length < 6 && password.length > 0){
      setMessage('Password should be equal or more than six characters')
      }
      else{
      if(password.length>=6 && password.length<=20){
     
        dispatch(register({firstName, lastName, email, phoneNum:PhoneNum, password}))
        setMessage(null)
        // EmailJS
    //     const templateParams = {
    //       name,
    //       email,
    //   }
    //      emailjs.send('service_5l37uk8', 'template_e8fka0a', templateParams, 'vBLCHftFf5nEceyQL')
    // .then((result) => {
    //     console.log(result.text);
    // }, (error) => {
    //     console.log(error.text);
    // });

      }
    // }
        }
    }
  }

const callback = (phoneNumber) => {
    setPhoneNum(phoneNumber)
}


  return (
    <React.Fragment>
      <Container className='formScreen pt-5 pb-5'>
    {loadingGoogleLogin ? <Loader/>: 
    <FormContainer>
       <h1>Create Your Account</h1>
       {message &&  <Message variant='danger'>{message}</Message>}
       {error && <Message variant='danger'>{error}</Message>}
       {loading && <Loader />}

    <Form as="form" className='form text-center' onSubmit={formSubmitHander}>

    <Form.Group className='mb-3 text-start' controlId='firstName'>
        <Form.Label>First Name</Form.Label>
        <Form.Control maxLength="12" type='text' placeholder='Enter First Name' pattern=".{3,}" required value={firstName}
         onChange={(event)=>setFirstName(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='lastName'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control maxLength="12" type='text' placeholder='Enter Last Name' pattern=".{3,}" required value={lastName}
         onChange={(event)=>setLastName(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='email'>
        <Form.Label >Email Address</Form.Label>
        <Form.Control maxLength="36" type='email' placeholder='Enter Email' pattern=".{3,}" required value={email}
         onChange={(event)=>setEmail(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start phoneInput'>
        <Form.Label>Phone Number</Form.Label>
        <PhoneNumber onChange={callback}/>
        <p><strong>Note:</strong> We only use this number for order's confirmation or shipping issue.</p>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='password'>
        <Form.Label>Password</Form.Label>
        <Form.Control maxLength="16" type='password' placeholder='Enter Password Of Six Characters Or More' required value={password}
         onChange={(event)=>setPassword(event.target.value)}></Form.Control>
      </Form.Group>

      <Form.Group className='mb-3 text-start' controlId='confirmPassword'>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control maxLength="16" type={!displayPassword ? 'password':'text'} placeholder='Confirm Password' required value={confirmPassword}
         onChange={(event)=>setConfirmPassword(event.target.value)}></Form.Control>
   <p className='text-end'>{!displayPassword ? 'Show':'Hide'}  <i className={!displayPassword ? 'fa-solid fa-eye':'fa-solid fa-eye-slash'} onClick={showPassword}></i></p>
      </Form.Group>
<Form.Group className='mb-3 text-start securityText'>
  <p className="text-start"><i className="fa-solid fa-lock"/> All data is transmitted encrypted via a secure TLS connection.</p>
</Form.Group>

      <button className='mb-3' type='submit' >Register</button>
    </Form>
    <Row className='py-3'>
        <Col>
       <p> Have An Account? <Link to={redirect ? `/login?redirect=${redirect}`:'/login'} onClick={signInHandler}>Sign In</Link></p>
        </Col>
    </Row>
    
</FormContainer>}
</Container>
</React.Fragment>
  )
}

export default RegisterScreen