import React, { useState } from "react";
import styles from "./Footer.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = ({ newsletter }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-newsletter", {
        email: email,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Form Sent Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <div className={styles.main}>
      <footer className={styles.footer}>
        <Container className="d-flex justify-content-center align-items-center flex-column">
          {newsletter && (
            <div className={styles.newsletter}>
              <div className={styles.pillCont}>
                <h1 className="h3_main">Newsletter</h1>
                <h1 className="heading_capital pb-4">Subscribe For Deals</h1>
                {loading ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                ) : success ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <p className="para_main" style={{ color: "white" }}>
                        {success}
                      </p>
                    </div>
                  </div>
                ) : (
                  error && (
                    <div className="row pb-4">
                      <div className="col-12">
                        <p className="para_main" style={{ color: "white" }}>
                          {error}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <form onSubmit={formSubmitHandler} className={styles.input}>
                  <input
                    required
                    type="email"
                    value={email}
                    maxLength="36"
                    placeholder="Your Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="button_main" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}

          <Row className="mt-5 gy-4">
            <Col lg={5}>
              <div className={styles.contentCol}>
                <img alt="" src="/images/logo_footer.png" />
                <p className="para_main">
                  We provide friendly, expert advice to our customers on a wide
                  array of solutions for Lithium LiFePO4 batteries, ECOFLOW
                  Systems, Solar Kits and Complex Solar and Energy Storage
                  Solutions.
                </p>
              </div>
            </Col>
            <Col lg={2}>
              <div className={styles.linkCol}>
                <Link to="/about">
                  <h4 className="h4_main">About</h4>
                </Link>
                <Link to="/shop">
                  <h4 className="h4_main">Shop</h4>
                </Link>
                <Link to="/battery-technology">
                  <h4 className="h4_main">Battery Technology</h4>
                </Link>
                <Link to="/solar-installation">
                  <h4 className="h4_main">Solar Installation</h4>
                </Link>
              </div>
            </Col>
            <Col lg={2}>
              <div className={styles.linkCol}>
                <Link to="/commercial">
                  <h4 className="h4_main">Commercial Projects</h4>
                </Link>
                <Link to="/warranty-and-return">
                  <h4 className="h4_main">Warranty & Return</h4>
                </Link>
                <Link to="/blog">
                  <h4 className="h4_main">Blog</h4>
                </Link>
                <Link to="/contact">
                  <h4 className="h4_main">Contact</h4>
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={styles.infoCol}>
                <a style={{ textDecoration: "none" }} href="tel: 905-299-7841">
                  <div className={styles.iconText}>
                    <img alt="" src="/images/footer_phone.svg" />{" "}
                    <p className="para_main">905 299 7841</p>
                  </div>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto: sales@truoffgrid.com"
                >
                  <div className={styles.iconText}>
                    <img alt="" src="/images/footer_email.svg" />{" "}
                    <p className="para_main">sales@truoffgrid.com</p>
                  </div>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  target="no_blank"
                  href="https://www.google.com/maps/dir//186+Sarah+St,+Milton,+ON+L9T+1H2,+Canada/@43.5108146,-79.963851,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x267e0b12cb8a4ddf:0xae5c0834e6f5863a!2m2!1d-79.8814503!2d43.5108442?entry=ttu"
                >
                  <div className={styles.iconText}>
                    <img alt="" src="/images/footer_location.svg" />{" "}
                    <p className="para_main">
                      186 Sarah St., Milton, Ontario, L9T 1H2
                    </p>
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className={styles.bottomRow}>
        <Container>
          <div className={styles.bottomRowInner}>
            <p className="para_main">
              Made with <span style={{ color: "red" }}>❤</span> by{" "}
              <a
                className="para_main"
                style={{ color: "red", textDecoration: "none" }}
                target="no_blank"
                href="https://www.creativesquad.ca/"
              >
                Creative Squad
              </a>
            </p>
            <p className="para_main">
              © Copyright 2024 Tru Off Grid - All Rights Reserved
            </p>
            <div className={styles.socials}>
              <a
                target="_blank"
                href="https://www.facebook.com/truoffgridsolarandlithiumpower/"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                </svg>
              </a>
              <a target="_blank" href="https://www.instagram.com/truoffgrid/">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </a>
              <a href="https://twitter.com/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/tru-off-grid/about/"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                </svg>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
