import React, { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./ProductsLoader.css";

const ProductsLoader = ({ length }) => {
  const steps = [];
  for (let i = 1; i <= length; i++) {
    steps.push(
      <Col sm={12} lg={6}>
        <Card style={{border:"none"}} className="my-3 p-3 rounded">
          <Card.Img className="card_image loading"></Card.Img>
          <Card.Body className="cards">
            <Card.Title className="card_title loading" as="div"></Card.Title>
            <Card.Text className="card_description loading"></Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  return (
    <Fragment>
      <Row>{steps}</Row>
    </Fragment>
  );
};

export default ProductsLoader;
