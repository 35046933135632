import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const ReturnPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
        <h1>{`Returns & Exchange`}</h1>
        <p>
          Dummy, We handle returns on a case-by-case basis with the ultimate
          goal of making our customers happy. We stand behind our goods and
          services, and want customers to be satisfied with them. We'll always
          do our best to take care of customers—our philosophy is to deal with
          them fairly and reasonably. We have long believed that when we treat
          our customers fairly, they in turn are fair with us. We apply refunds
          to the tender with which returned items were purchased. If we choose
          to provide a refund and no record of sale is available, we'll ask for
          personal identification and a refund will be provided at the current
          price on a Nordstrom Gift Card.
        </p>
        <h3>Terms Of Refund</h3>
        <p>
          Dummy, Need to make a return? We no longer send return labels with
          orders, but you can still print your own prepaid label, get a QR code
          to use at eligible USPS locations, or make your return in store.
        </p>
        <h3>How can you refund</h3>
        <p>
          Dummy, We now accept returns of Nordstrom, Nordstrom.com and
          Nordstrom.ca merchandise at Nordstrom Rack locations. Boutique returns
          must be processed at the store where they were originally purchased.{" "}
          <Link to="/support-sufaid">Support</Link>
        </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ReturnPolicyScreen;
