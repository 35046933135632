import React from "react";
import styles from "./CommonHero.module.css";
import Header from "../Header_/Header";
import { Container } from "react-bootstrap";

const CommonHero = ({ image, heading, subHeading, text, span, shopHero }) => {
  return (
    <div
      style={{ backgroundImage: `url('${image}')` }}
      className={shopHero ? `${styles.shopHero} ${styles.main}` : styles.main}
    >
      <Header />
      <div className={styles.inner}>
        <Container>
          <div className={styles.contentCont}>
            <h2 className="h2_main">{subHeading}</h2>
            <h1 className="heading_capital">
              {heading} <span>{span}</span>
            </h1>
            <p className="para_main ">{text}</p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CommonHero;
