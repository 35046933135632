import React from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import CallToAction from "../../components/CallToAction/CallToAction";
import Reviews from "../../components/Reviews_/Reviews";

const Partners = () => {
  return (
    <>
      <CommonHero
        image={"/images/hero-partners.jpg"}
        heading="Our"
        span="Partners"
        text="TRU Off Grid partners only with high quality companies and brands that back their technology. Dakota Lithium and Ecoflow are two of those companies who manufacture high quality, innovative products that are backed by industry leading warranty and align with our business vision, mission and objective; to provide Canadians with high quality, sustainable, clean energy solutions."
      />
      <AboutSec
        subHeading={"11 Year Warranty"}
        heading={"DAKOTA LITHIUM"}
        text={`Dakota Lithium offers customers an 11 Year industry leading warranty and believes in the impact of quality when it comes to batteries, and that quality is best assessed by their lifespan and how long they endure. This principle drives their commitment to crafting batteries that stand the test of time. Leveraging their expertise, Dakota Lithium proudly produces batteries that outlast traditional ones by an impressive 4x margin. By distributing Dakotas products, TRU Off Grid delivers lasting value to our esteemed customers, while also championing the cause of reducing e-waste and minimizing our impact on the environment.
        <br/><br/>
        At TRU Off Grid, our partnership with Dakota Lithium is rooted in a shared mission: To make a positive impact on both individuals and our environment. Together, we are dedicated to producing eco-friendly solutions that cater to deep cycle battery needs and provide enduring energy storage. Our goal is to empower your pursuits and contribute to a greener world, one step at a time.`}
        img={"/images/partners1.png"}
      />
      <AboutSec
        subHeading={"5 Year Warranty"}
        heading={"ECOFLOW"}
        text={`In 2017, Ecoflow was established by entrepreneurs who had previously honed their expertise in perfecting drone batteries – making them lightweight, durable, and notably powerful. Leveraging this knowledge and experience, Ecoflow leads the industry with smart energy storage solutions and offers a 5 Year Warranty.
        <br/><br/>
        Ecoflow’s product lines offer consumers an abundant supply of clean, silent, and renewable power for various life adventures, work, and home backup needs. With an industrial-grade capacity, our products empower users to embrace limitless possibilities in the pursuit of energy independence.
        <br/><br/>
        Ecoflow’s mission is to transform the way people access power by championing clean energy solutions, displacing traditional gas generators, and catalyzing a worldwide energy revolution. Through technology and inclusivity, Ecoflow empower’s regions grappling with power shortages, nurturing a more vibrant and sustainable future`}
        img={"/images/partners2.png"}
        reverse={true}
        padding="80px 0px 220px 0px"
      />
      <Reviews />
      <Footer newsletter={true} />
    </>
  );
};

export default Partners;
