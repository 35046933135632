import React from "react";
import { Alert } from "react-bootstrap";

const Message =({variant, children})=>{

    return(
        <Alert variant={variant}>
           {variant==='success' ? <div>{children} <i className="fa-solid fa-check"></i></div> : variant==='danger' ? <div><i style={{fontSize:'24px'}} className="fa-solid fa-circle-exclamation"></i> {children}</div> : <div>{children}</div>}
        </Alert>
    )
}

export default Message