import React from "react";
import styles from "./IdvProduct.module.css";
import { Link } from "react-router-dom";

const IdvProduct = ({ product, productPage }) => {
  return (
    <div className={styles.cont}>
      <div className={styles.boxCont}>
        <Link
          style={{ width: "100%", height: "100%", textDecoration: "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          to={`/product/${product.url ? product.url : product._id}`}
        >
          <img
            alt={product.name}
            src={product.image}
            className={
              productPage
                ? `${styles.productPage} ${styles.box}`
                : `${styles.box}`
            }
          ></img>
          <div className={styles.content}>
            <h3 className="h3_main">{product.name}</h3>
            <div className={styles.foot}>
              {product.sellPrice || product.sellPrice !== 0 ? (
                <>
                  <h3 className="h3_main">${product.price}</h3>
                  <h4 className="h3_main">${product.sellPrice}</h4>
                </>
              ) : (
                <>
                  <h4 className="h3_main">${product.price}</h4>
                </>
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default IdvProduct;
