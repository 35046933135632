import React, { Fragment } from "react";
import Footer from "../../components/Footer_/Footer";
import BlogSec from "../../components/BlogSec_/BlogSec";
import Reviews from "../../components/Reviews_/Reviews";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import Hero from "../../components/Hero/Hero";
import AboutSec from "../../components/AboutSec_/AboutSec";
import Services from "../../components/Services_/Services";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

const HomeScreen = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          TRU Off Grid | Lithium Batteries & Solar Solutions in Milton, ON
        </title>
        <link rel="canonical" href="https://truoffgrid.com/" />
        <meta
          name="description"
          content="TRU Off Grid offers LiFePO4 batteries, EcoFlow kits, and solar systems. Family-owned in Milton, ON. Great prices, free shipping, and expert customer service."
        />
      </Helmet>
      <Hero />
      <CategoriesRow />
      <Services />
      <AboutSec
        heading={"ABOUT TRU OFF GRID"}
        subHeading={"Who We Are"}
        text={`We are a family business located in Milton, Ontario focused on renewable and  
        clean energy solutions. We strive to offer our customers the best value for  
        their dollar and educate them on our products and future innovation. We  
        partner with companies offering quality products that are rigorously quality  
        assured, have strong warranty programs and are safe and useful. Please call  
        Jack Abrams at <a href="tel:+19052997841">905 299 7841</a>
        `}
        buttonLink={"/about"}
        buttonText={"About us"}
        img={"/images/about.webp"}
      />
       <AboutSec
        heading={"We are Powering Life Anywhere"}
        text={`We supply innovative, sustainable and clean energy solutions. We offer a wide range
          of Lithium LiFePO4 battery, ECOFLOW Solar Kits, Solar Panels and Solar Systems to
          power your passions including Van, RV, Cottage, Home, Business or Commercial
          Applications. Please call Jack Abrams at <a href="tel:+19052997841">905 299 7841</a>`}
        buttonText={"Shop Now"}
        buttonLink={"/shop"}
        reverse={true}
        backgroundImage={true}
        img={"/images/video.png"}
        imgLink={"https://www.youtube.com/watch?v=htcdoUEYJF0"}
      />
      <AboutSec
        heading={"SOLAR and ENERGY STORAGE INSTALLATION"}
        text={`We provide Grid Tie, Net Metre, Off Grid and Mobile Solar Installation
        Services through our Sister Company: URT Solar. URT stands for
        “Ultimate Renewable Technologies”. Please call Jack Abrams at <a href="tel:+19052997841">905
        299 7841</a> or email at <a href="mailto:sales@urtsolar.ca">sales@urtsolar.ca</a>
        `}
        buttonLink={"https://urtsolar.ca/"}
        buttonText={"Connect with URT Solar"}
        img={"/images/urt.jpg"}
      />
      {/* <CategoriesSec />
      <MainCategoriesSec /> */}
      <CallToAction />
      <BlogSec />
      <Reviews />
      <Footer newsletter={true} />
    </Fragment>
  );
};

export default HomeScreen;
