import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./CategoriesRow.module.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IdvProduct from "../IdvProduct_/IdvProduct";
import { listProducts } from "../../actions/productActions";
import { Autoplay } from "swiper";
import ProductsLoader from "../ProductLoader/ProductsLoader";
import Message from "../Message";
import axios from "axios";

const CategoriesRow = ({ heading, productScreen }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const params = useParams();
  const productId = params.id;

  const [recommendedProducts, setRecommendedProducts] = useState();
  const [loadingRecommended, setLoadingRecommended] = useState(true);
  const [errorRecommended, setErrorRecommeded] = useState(null);

  useEffect(() => {
    if (!productScreen) {
      dispatch(listProducts());
    }
  }, [dispatch]);

  useEffect(() => {
    if (productScreen) {
      const fetchProduct = async () => {
        try {
          const { data } = await axios.get(
            `/api/products/recommended/${productId}`
          );
          setRecommendedProducts(data);
          setLoadingRecommended(false);
        } catch (error) {
          setErrorRecommeded(error.message);
          setLoadingRecommended(false);
        }
      };
      fetchProduct();
    }
  }, [productId]);

  return (
    <div className="main">
      <Container>
        <h2 className="h2_main text-center pb-2">EXPLORE</h2>
        <h1 className="h1_main text-center pb-5">
          {heading ? heading : `FEATURED PRODUCTS`}
        </h1>
        <Row className="pt-3">
          <div className={styles.swiper_box}>
            {!productScreen && (
              <Swiper
                style={{ width: "100%" }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {loading ? (
                  <ProductsLoader length={2} />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {products.map(
                      (product) =>
                        product.recommended && (
                          <SwiperSlide
                            className={styles.swiperSlide}
                            key={product._id}
                          >
                            <IdvProduct product={product} />
                          </SwiperSlide>
                        )
                    )}
                  </>
                )}
              </Swiper>
            )}
            {productScreen && (
              <Swiper
                style={{ width: "100%" }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  200: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {loadingRecommended ? (
                  <ProductsLoader length={2} />
                ) : errorRecommended ? (
                  <Message variant="danger">{errorRecommended}</Message>
                ) : (
                  Array.isArray(recommendedProducts) &&
                  !loadingRecommended &&
                  recommendedProducts.map((product) => (
                    <SwiperSlide
                      className={styles.swiperSlide}
                      key={product._id}
                    >
                      <IdvProduct product={product} />
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesRow;
