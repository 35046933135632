import React, { Fragment, useEffect } from "react";
// import HeroBanner from "../../Components/HeroBanner/HeroBanner";
import BlogsPortalListings from "../../components/Blogs & Landing Page/BlogsPortalListings";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BlogsScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      history("/");
    }
  }, [dispatch, history, userInfo]);

  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <HeroBanner
        title="Blogs & News"
        para={"Creative Squad Blogs Portal"}
        btnReq={false}
        txtCenter={true}
      /> */}
      <BlogsPortalListings />
    </Fragment>
  );
};

export default BlogsScreen;
