import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { login } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import GoogleLoginButton from "../../components/GoogleLogin";
import { gapi } from "gapi-script";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const RTlxe =
    "963026223590-2holqk3v74homa7guk2v260cu4qpgetv.apps.googleusercontent.com";

  useEffect(() => {
    if (userInfo && redirect) {
      history(`/${redirect}`);
    } else {
      if (userInfo) {
        history("/home");
      }
    }

    function start() {
      gapi.client.init({
        client_id: RTlxe,
        scope: "",
      });
    }

    gapi.load("client:auth2", start);
  }, [dispatch, history, userInfo, redirect]);

  const showPassword = () => {
    setDisplayPassword(!displayPassword);
  };

  const formSubmitHander = (event) => {
    event.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <div className="main">
      <Container className="formScreen">
        <h1>Sign In</h1>
        <FormContainer>
          <Form as="form" className="text-center"></Form>
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          <Form
            as="form"
            className="form text-center"
            onSubmit={formSubmitHander}
          >
            <Form.Group className="mb-3 text-start" controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                maxLength="36"
                required
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                maxLength="16"
                required
                type={!displayPassword ? "password" : "text"}
                placeholder="Enter Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col className="col-xs-6 text-start">
                  <Link className="text-start" to="/accountrecovery">
                    Forgot Password?
                  </Link>
                </Col>
                <Col className="col-xs-6 text-end">
                  <p className="text-end">
                    {!displayPassword ? "Show" : "Hide"}{" "}
                    <i
                      className={
                        !displayPassword
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }
                      onClick={showPassword}
                    ></i>
                  </p>
                </Col>
              </Row>
            </Form.Group>
            <button className=" mt-3" type="submit">
              Sign In
            </button>
            <div className="orText mt-3">
              <h1>Or</h1>
            </div>
            <FormGroup className="mb-3">
              <div>
                <GoogleLoginButton classname={"googleButton"} />
              </div>
            </FormGroup>
          </Form>
          <Row className="py-3">
            <Col>
              <p>
                {" "}
                Not registered yet?{" "}
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to={redirect ? `/register?redirect=${redirect}` : "/register"}
                >
                  Create Your Account
                </Link>
              </p>
            </Col>
          </Row>
        </FormContainer>
      </Container>
    </div>
  );
};

export default LoginScreen;
