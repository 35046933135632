import React from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Services from "../../components/Services_/Services";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

const Commercial = () => {
  return (
    <>
      <Helmet>
        <title>
          TRU Off Grid | Custom Solar & Lithium Solutions for Businesses
        </title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="TRU Off Grid specializes in advanced solar and lithium battery kits for engineering, trucking, and Ontario Townships. Contact us for sustainable power solutions tailored to your needs."
        />
      </Helmet>
      <CommonHero
        image={"/images/hero-commercial.jpg"}
        heading="TRU Off Grid Commercial"
      />
      <AboutSec
        heading={"Custom Commercial Projects"}
        text={`TRU Off Grid has worked with a diverse range of commercial clients in the
        engineering and trucking industry, as well as Ontario Townships, customizing unique
        solutions to meet their specific power requirements. Through our skillful integration of
        advanced solar and lithium battery kits, we ensure seamless and sustainable operations
        for your business. We are ready to tackle your project! Contact us today.`}
        img={"/images/commercial1.png"}
        cta={true}
      />
      <CallToAction cta3={true} />
      <div style={{ padding: "80px 0px 220px 0px" }}></div>
      <Footer newsletter={true} />
    </>
  );
};

export default Commercial;
