import {GoogleLogin} from 'react-google-login'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { googleLogin } from '../actions/userActions'
import { useDispatch } from 'react-redux'
import Message from '../components/Message'

const GoogleLoginButton = (props) => {

  const [googleError, setGoogleError]= useState(null)

const history=useNavigate()
const dispatch=useDispatch()

    const xleRt2= '963026223590-2holqk3v74homa7guk2v260cu4qpgetv.apps.googleusercontent.com'

        function successHandler(res){

            const requestData = JSON.stringify({firstName:res.profileObj.givenName, lastName:res.profileObj.familyName, email:res.profileObj.email, id:res.profileObj.googleId})
            const googleData = JSON.parse(requestData)
            console.log(googleData)
            dispatch(googleLogin({email: googleData.email, id:googleData.id, firstName:googleData.firstName, lastName:googleData.lastName}))
        }
        
        const userLogin = useSelector((state)=> state.userLogin) 
        const {userInfo}= userLogin

        const userGoogleLogin = useSelector((state)=> state.userGoogleLogin) 
        const {success, error}= userGoogleLogin
       
        if(error){
          console.log(error)
        }
            
        if (success){
          window.location.reload(false);
        }

useEffect(()=>{

  // if(!userInfo && requestData){   
  //      history('/register')
  //    }
  //    else{
      if(userInfo){
        history('/')
      }
     //}
},[userInfo, history, dispatch])
      
    const failureHandler=(res)=>{
        console.log('LOGIN Failed!', res)
         setGoogleError('Google Authentication Failed')
    }

  return (
    <div>
{googleError && <Message variant='danger'>{googleError}</Message>}
<GoogleLogin
clientId={xleRt2}
  render={renderProps => (
   <button className={props.classname} onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fa-brands fa-google"></i> Sign In With Google</button>
)}
buttonText='Sign In With Google'
onSuccess={successHandler}
onFailure={failureHandler}
cookiePolicy={'single_host_origin'}
/>
    </div>
  )
}

export default GoogleLoginButton