import React, { useState } from "react";
import { Row, Col, Container, Form, Spinner } from "react-bootstrap";
import styles from "./ContactScreen.module.css";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import axios from "axios";

const ContactScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form", {
        name,
        email,
        phoneNumber: phoneNum,
        message,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Form Submitted Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <>
      <Header />
      <div className="main">
        <Container>
          <Row className="gy-4 gx-5">
            <Col lg={6}>
              <div className={styles.contentCol}>
                <h2 className="h2_main pb-2">Contact us</h2>
                <h1 className="h1_main pb-4">
                  Need help with your battery or solar project?{" "}
                  <span>Lets Talk</span>
                </h1>
                <Row>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel: 905-299-7841"
                    >
                      <div className={styles.info}>
                        <img src="/images/phone.svg" />
                        <p className="para_main">905-299-7841</p>
                      </div>
                    </a>
                  </Col>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto: sales@truoffgrid.com"
                    >
                      <div className={styles.info}>
                        <img src="/images/mail.svg" />
                        <p className="para_main">sales@truoffgrid.com</p>
                      </div>
                    </a>
                  </Col>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      target="no_blank"
                      href="https://www.google.com/maps/dir//186+Sarah+St,+Milton,+ON+L9T+1H2,+Canada/@43.5108146,-79.963851,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x267e0b12cb8a4ddf:0xae5c0834e6f5863a!2m2!1d-79.8814503!2d43.5108442?entry=ttu"
                    >
                      <div className={styles.info}>
                        <img src="/images/location.svg" />
                        <p className="para_main">
                          186 Sarah Street, Milton, ON (We See Customers "By
                          Appointment" Only. Please call Jack Abrams. We are not
                          a retail store. Thank you)
                        </p>
                      </div>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.formCol}>
                <h1 className="h1_main pb-2">Let’s get in touch!</h1>
                <p className="para_main pb-5">
                  Lets discuss your project and find out what we can do
                </p>
                {loading ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "black" }}
                      />
                    </div>
                  </div>
                ) : success ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <p className="para_main" style={{ color: "black" }}>
                        {success}
                      </p>
                    </div>
                  </div>
                ) : (
                  error && (
                    <div className="row pb-4">
                      <div className="col-12">
                        <p className="para_main" style={{ color: "black" }}>
                          {error}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <form onSubmit={formSubmitHandler}>
                  <input
                    maxLength="12"
                    type="text"
                    placeholder="Your Full Name"
                    pattern=".{3,}"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="email"
                    placeholder="Email"
                    pattern=".{3,}"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="text"
                    placeholder="Phone Number"
                    pattern=".{3,}"
                    required
                    value={phoneNum}
                    onChange={(event) => setPhoneNum(event.target.value)}
                  />
                  <input
                    style={{ resize: "none" }}
                    as="textarea"
                    maxLength="800"
                    type="text"
                    placeholder="Your Message"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <button className="button_main mt-3" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ContactScreen;
