import React from "react";
import BlogSec from "../../components/BlogSec/BlogSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Footer from "../../components/Footer_/Footer";

const Blog = () => {
  return (
    <>
      <CommonHero image="/images/hero-shop.jpg" heading="BLOGS" />
      <BlogSec />
      <Footer />
    </>
  );
};

export default Blog;
