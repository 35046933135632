import React from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Services from "../../components/Services_/Services";
import { Helmet } from "react-helmet";

const BatteryTechnology = () => {
  return (
    <>
      <Helmet>
        <title>TRU Off Grid | Leading LiFePO4 Battery Solutions</title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="Discover TRU Off Grid's top-tier LiFePO4 battery solutions for Recreation, Off-Grid living, Residential use, and Solar Systems. Partnered with industry leaders for quality and reliability."
        />
      </Helmet>
      <CommonHero
        image={"/images/hero-batterytechnology.jpg"}
        heading="BATTERY TECHNOLGY"
      />
      <AboutSec
        heading={"LPF Battery Solutions"}
        subHeading={"TRU’s Preferred Battery Chemistry"}
        text={`TRU Off Grid is primarily focused on cutting-edge LiFeP04 (Lithium Iron Phosphate)
        battery solutions tailored for a wide range of applications. We specialize in providing top-tier
        battery solutions designed for Recreation (Land and Water), Off-Grid living, Residential use, and
        Solar Systems. Our strategic partnerships with industry leaders demonstrate our dedication to
        quality and guarantee that you will have access to the best lithium battery solutions available.
        As a proud partner of Dakota Lithium (USA), VOLTHIUM (Quebec), LYNAC (Thunder Bay),
        ECOFLOW (USA), and Canadian Solar, TRU Off Grid brings you a broad selection of
        high-performance and reliable solutions.`}
        img={"/images/battery1.png"}
        cta={true}
      />
      <Services />
      <AboutSec
        heading={"LPF - Safety"}
        subHeading={"Safer Than You Think!"}
        text={`LiFePO4 or LFP batteries are the safest type of lithium battery on the market,
        they are highly stable and not prone to overheating. Even if they’re punctured, they
        won’t catch on fire. The cathode material in LiFePO4 is also non-hazardous material
        and therefore it poses no environmental or negative health hazards. LFP is free of rare
        and heavy metals, non-polluting, non-toxic, and in compliance with Canadian
        requirements.`}
        img={"/images/battery2.png"}
        reverse={true}
        cta={true}
      />
      <AboutSec
        heading={"LPF – Why Are They Great?"}
        subHeading={"Best In The Game"}
        text={`Energy capacity and low self-discharge rate: LiFePO4 or LFP batteries can be
        discharged up to 100% without being damaged, they offer a much higher energy capacity than
        lead-acid batteries, which can only be discharged up to 50% of their storage capacity. LFP
        batteries have a lower self-discharge rate compared to some other battery technologies. This
        means they hold their charge longer when not in use, which is handy for applications where 
        power is used intermittently. LFP batteries only lose an average of 3% charge per month of
        inactivity. It is still advisable to unplug them when the expected period of non-use is greater than
        3 months.`}
        img={"/images/battery3.png"}
        cta={true}
      />
      <AboutSec
        heading={"LFP – Additional Benefits"}
        subHeading={"It Doesn’t Get Better!"}
        text={`● Long battery life cycle: LFP batteries have a life-cycle of up to 6,000 cycles
        compared to 300 cycles for long-life lead acid batteries. Theoretically, an LFP
        battery could last for 15 years.<br/><br/>
        ● Performs well at temperatures: LFP batteries perform well at extremes of
        temperature with an operating range of -20C to +75C. Self Heating LFP batteries
        allow for charging in freezing temperatures.<br/><br/>
        ● Lightweight: A LiFePO4 battery weighs one third that of lead-acid batteries.`}
        img={"/images/battery4.png"}
        reverse={true}
        padding="80px 0px 220px 0px"
        cta={true}
      />
      <Footer newsletter={true} />
    </>
  );
};

export default BatteryTechnology;
