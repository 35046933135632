import axios from "axios";

export const addToCart = (id, quantity) => {
  return async (dispatch, getState) => {
    const { data } = await axios.get(`/api/products/${id}`);

    dispatch({
      type: "ADD_ITEM",
      payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.sellPrice ? data.sellPrice : data.price,
        countInStock: data.countInStock,
        quantity,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };
};

export const removeFromCart = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };
};

export const removeCartItems = () => {
  return () => {
    localStorage.removeItem("cartItems");
  };
};

export const saveShippingAddress = (data) => {
  return (dispatch) => {
    dispatch({ type: "CART_SAVE_SHIPPING_ADDRESS", payload: data });

    localStorage.setItem("shippingAddress", JSON.stringify(data));
  };
};

export const savePaymentMethod = (data) => {
  return (dispatch) => {
    dispatch({ type: "CART_SAVE_PAYMENT_METHOD", payload: data });

    localStorage.setItem("paymentMethod", JSON.stringify(data));
  };
};

export const addToCartAnimate = () => {
  return (dispatch) => {
    dispatch({
      type: "ADD_ITEM_ANIMATE",
      payload: true,
    });
  };
};
