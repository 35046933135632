import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./BlogSec.module.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const BlogSec = () => {
  const [popularBlogs, setPopularBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blog`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Popular_blogs_data = [];
          res.data.data.forEach((item) => {
            Popular_blogs_data.push({
              blog_id: item.blog_id,
              heading: item.title,
              paragraph: item.brief_paragraph,
              slug_url: item.slug_url,
              creative: item.blog_image,
              date: item.published_date,
            });
          });
          setPopularBlogs(Popular_blogs_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);

  return (
    <div className="main">
      <Container>
        <h2 className="h2_main text-center pb-2">NEWS & FEEDS</h2>
        <h1 className="h1_main text-center pb-5">Popular Blogs</h1>
        {loading && !popularBlogs ? (
          <div
            style={{ width: "100%", height: "100%" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "red", width: "120px", height: "120px" }}
            />
          </div>
        ) : (
          popularBlogs && (
            <Row className="gy-4">
              {popularBlogs &&
                popularBlogs.map((item) => (
                  <Col lg={4}>
                    <div className={styles.box}>
                      <Link to={`/blogs/${item.slug_url}`}>
                        <img alt="" src={item.creative} />
                      </Link>
                      <div className={styles.contentCol}>
                        <h3 className="h3_main">{item.heading}</h3>
                        <div className={styles.foot}>
                          <div className={styles.readMore}>
                            <img alt="" src="/images/calendar.svg" />
                            <p className="para_main">{item.date}</p>
                          </div>
                          <Link to={`/blogs/${item.slug_url}`}>
                            <h4 className="h4_main">Read More</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          )
        )}
      </Container>
    </div>
  );
};

export default BlogSec;
