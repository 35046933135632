import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image, Container, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProductDetails } from "../../actions/productActions";
import Message from "../../components/Message";
import { addToCartAnimate } from "../../actions/cartActions";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Zoom } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import styles from "./ProductScreen.module.css";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import Reviews from "../../components/Reviews_/Reviews";
import { Helmet } from "react-helmet";

const ProductScreen = () => {
  const params = useParams();
  const productId = params.id;
  const [quantity, setQuantity] = useState(1);

  const productDetails = useSelector((state) => state.productDetails);
  const { error, product } = productDetails;
  const dispatch = useDispatch();

  const history = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const productRedirects = {
    "lynac-lithium-12v-100ah-battery-with-bluetooth-monitoring-and-self-heating":
      "volthium-12v-100ah-deep-cycle-lithium-battery-with-self-heating-soft-start-and-deep-cycle-power",
    "dakota-lithium-12v-54ah-battery-weighing-only-10-lbs-on-sale-for-kayak-fishing-trolling-motors-45-pounds-thrust-or-smaller-and-scooters-in-stock-and-free-shipping":
      "dakota-lithium-12v-54ah-battery-weighing-only-14-lbs-on-sale-for-kayak-fishing-trolling-motors-45-pounds-thrust-or-smaller-and-scooters-in-stock-and-free-shipping",
    "ecoflow-delta-max":
      "ecoflow-delta2-max-2000-watt-portable-power-station-and-solar-generator",
    "ecoflow-wave-2-portable-air-conditioner-and-heater-with-1260-watt-lifepo4-battery-highly-efficient-light-and-simple-to-set-up-in-stock-at-tru-off-grid":
      "ecoflow-wave-2-portable-air-conditioner-and-heater-highly-efficient-light-and-simple-to-set-up-in-stock-at-tru-off-grid",
  };

  useEffect(() => {
    const currentPath = location.pathname.replace(/^\/|\/$/g, "");
    const currentSlug = currentPath.split("/").pop();
    if (productRedirects[currentSlug]) {
      navigate(`/product/${productRedirects[currentSlug]}`, { replace: true });
      window.location.reload();
    }
  }, [productId, location, navigate]);

  useEffect(() => {
    if (thumbsSwiper?.destroyed) {
      setThumbsSwiper(null);
    }
    return () => {
      setThumbsSwiper(null);
    };
  }, []);

  useEffect(() => {
    dispatch({ type: "PRODUCT_DETAILS_RESET" });
    dispatch(listProductDetails(productId));
  }, [dispatch, productId]);

  const addToCartHandler = () => {
    dispatch({ type: "ADD_ITEM_ANIMATE_RESET" });
    dispatch(addToCartAnimate());
    history(`/cart/${params.id}?quantity=${quantity}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{product.metaTitle}</title>
        <meta name="keywords" content={product.metaKeyword} />
        <link rel="canonical" href={product.canonical} />
        <meta name="description" content={product.metaDescription} />
      </Helmet>
      <Header />
      <div className="main">
        <Container>
          {error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            product && (
              <Row className="gy-4">
                <Col lg={6}>
                  {product.image !== "none" && (
                    <>
                      <Swiper
                        spaceBetween={0}
                        zoom={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Thumbs, Zoom]}
                        className={styles.swiper2}
                      >
                        {product.image !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                        {product.image2 !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image2}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                        {product.image3 !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image3}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                        {product.image4 !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image4}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                        {product.image5 !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image5}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                        {product.image6 !== "none" && (
                          <SwiperSlide>
                            <div className="swiper-zoom-container">
                              <Image
                                className={styles.swiper2Img}
                                src={product.image6}
                                fluid
                              />
                            </div>
                          </SwiperSlide>
                        )}
                      </Swiper>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className={styles.swiper}
                      >
                        {product.image !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image} fluid />
                          </SwiperSlide>
                        )}
                        {product.image2 !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image2} fluid />
                          </SwiperSlide>
                        )}
                        {product.image3 !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image3} fluid />
                          </SwiperSlide>
                        )}
                        {product.image4 !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image4} fluid />
                          </SwiperSlide>
                        )}
                        {product.image5 !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image5} fluid />
                          </SwiperSlide>
                        )}
                        {product.image6 !== "none" && (
                          <SwiperSlide>
                            <Image src={product.image6} fluid />
                          </SwiperSlide>
                        )}
                      </Swiper>
                    </>
                  )}
                </Col>
                <Col lg={6}>
                  <div className={styles.box}>
                    <div className={styles.headingCont}>
                      <h2 className="h2_main">{product.name}</h2>
                      <div className={styles.priceRow}>
                        {product.sellPrice || product.sellPrice !== 0 ? (
                          <>
                            <h3 className="h2_main">${product.price}.00</h3>
                            <h4 className="h2_main">${product.sellPrice}.00</h4>
                          </>
                        ) : (
                          <>
                            <h4 className="h2_main">${product.price}.00</h4>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.customCont}>
                      <div className={styles.custom1}>
                        <h3 className="h3_main">Availability:</h3>
                      </div>
                      <div className={styles.custom2}>
                        <h4 className="h4_main">
                          {product.countInStock > 0
                            ? `${product.countInStock} In Stock`
                            : "Out Of Stock"}
                        </h4>
                      </div>
                    </div>
                    <div className={styles.infoCont}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                        className="para_main"
                      />
                    </div>
                    <div className={styles.buttonCont}>
                      <Button
                        className={`${styles.addToCart} button_main`}
                        type="button"
                        disabled={product.countInStock === 0}
                        onClick={addToCartHandler}
                      >
                        Add To Cart
                      </Button>
                      {product.countInStock > 0 && (
                        <div className={styles.input}>
                          <img
                            onClick={() =>
                              quantity < product.countInStock &&
                              setQuantity(quantity + 1)
                            }
                            src="/images/plus_icon.svg"
                          />
                          <span>{quantity}</span>
                          <img
                            onClick={() =>
                              quantity > 1 && setQuantity(quantity - 1)
                            }
                            src="/images/minus_icon.svg"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )
          )}
        </Container>
      </div>

      <CategoriesRow
        productScreen={true}
        heading="Recommended Products"
        swiper={true}
      />
      <Reviews />
      <Footer />
    </>
  );
};

export default ProductScreen;
