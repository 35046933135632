import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  ListGroup,
  Card,
  Image,
  Button,
  Container,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import {
  deliverOrder,
  getOrderDetails,
} from "../../actions/orderActions";
import { listProducts } from "../../actions/productActions";
import Loader from "../../components/Loader";

const OrderScreen = () => {

  const dispatch = useDispatch();
  const history = useNavigate();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { success: successCreate } = orderCreate;

  const productList = useSelector((state) => state.productList);
  const { loading: loadingProducts, products } = productList;

  const params = useParams();
  const orderId = params.id;

  const imgBack = () => {
    history(-1);
  };

  let OrderItems = null;
  let paypalPayment = null;

  if (!loading) {
    order.itemsPrice = order.orderItems.reduce(
      (total, item) =>
        total + (item.sellPrice ? item.sellPrice : item.price) * item.quantity,
      0
    );

    const itemsArray = order.orderItems;

    OrderItems = products.filter(function (o) {
      return itemsArray.some(function (o2) {
        return o._id === o2.product;
      });
    });

    paypalPayment = (
      order.discountedPrice ? order.discountedPrice : order.totalPrice
    ).toFixed(2);
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  useEffect(() => {
    // if (!userInfo) {
    //   history("/login");
    // } else {
    dispatch(listProducts());
    if (!order || successCreate || successDeliver) {
      dispatch({ type: "ORDER_DELIVER_RESET" });
      dispatch(getOrderDetails(orderId));
      dispatch({ type: "ORDER_CREATE_RESET" });
      dispatch({ type: "ORDER_DETAILS_RESET" });
    } 
    // }
  }, [
    dispatch,
    order,
    orderId,
    successDeliver,
    successCreate,
    // userInfo,
    history,
  ]);

  return (
    <Fragment>
      <Container
        style={{ marginTop: "20px", marginBottom: "20px" }}
        className="orderingScreens orderingScreensNoMargin"
      >
        {loading || loadingProducts ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Fragment>
            {/* <Link to='/myorders'></Link> */}
            <img
              onClick={imgBack}
              className="img-back"
              src="/images/arrow-left.svg"
            ></img>
            <h1 className="orderId">
              Order: <span>{order._id}</span>
            </h1>
            {!order.user && <p className="text-center">Order was done in guest mode, check email for details.</p>}
            <Row>
              <Col lg={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    
                    <h3>Shipping</h3>
                    <h6>
                      Name: {order.shippingAddress.firstName}{" "}
                      {order.shippingAddress.lastName}{" "}
                    </h6>
                    <h6>Email: {order.shippingAddress.email}</h6>
                    <h6>Phone: {order.shippingAddress.phoneNum}</h6>
                    <h6>
                      Address: {order.shippingAddress.address},{" "}
                      {order.shippingAddress.province},{" "}
                      {order.shippingAddress.city} {""}
                      {order.shippingAddress.postalCode}, {""}
                      {order.shippingAddress.country}
                    </h6>
                    <h6>
                      {order.isDelivered ? (
                        <span style={{ color: "green" }} variant="success">
                          Status: Delivered on{" "}
                          {order.deliveredAt.substring(0, 10)}{" "}
                        </span>
                      ) : (
                        <span variant="danger">Status: Not Delivered</span>
                      )}
                    </h6>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h3>Payment Method</h3>
                    <h6>Method: {order.paymentMethod}</h6>
                    <h6>
                      {" "}
                      {order.isPaid && order.paymentMethod === "PayPal" ? (
                        <span style={{ color: "green" }} variant="success">
                          Status: Paid on {order.paidAt.substring(0, 10)}{" "}
                        </span>
                      ) : (
                        order.paymentMethod === "PayPal" && (
                          <span style={{ color: "red" }} variant="danger">
                            Status: Not Paid
                          </span>
                        )
                      )}
                      {order.paymentMethod === "Cash On Delivery" &&
                      order.isDelivered ? (
                        <span variant="success">
                          Status: Paid on {order.deliveredAt.substring(0, 10)}{" "}
                        </span>
                      ) : order.paymentMethod === "Cash On Delivery" &&
                        !order.isDelivered ? (
                        <span variant="danger">Status: Not Paid</span>
                      ) : (
                        ""
                      )}
                    </h6>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <h3>Order Items</h3>
                    {!loading && !error && order.orderItems.length === 0 ? (
                      <Message>Your Cart is empty</Message>
                    ) : (
                      <ListGroup variant="flush">
                        {!loading &&
                          !error &&
                          OrderItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col md={2}>
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    fluid
                                    rounded
                                  ></Image>
                                </Col>
                                <Col>
                                  <h5>{item.name}</h5>
                                </Col>
                                <Col md={4}>
                                  <h6>Quantity:</h6>{" "}
                                  <p>
                                    {
                                      order.orderItems.find(
                                        (prdt) => prdt.product === item._id
                                      ).quantity
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col lg={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Order Summary</h2>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Items</h5>
                        </Col>
                        <Col>
                          <p>${order.itemsPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Shipping</h5>
                        </Col>
                        <Col>
                          <p>${order.shippingPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Tax HST</h5>
                        </Col>
                        <Col>
                          <p>${order.taxPrice}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h5>Total Price</h5>
                        </Col>
                        <Col>
                          <p>${order.totalPrice}</p>
                        </Col>
                      </Row>
                      {order.discountedPrice && (
                        <Row>
                          <Col>
                            <h5>Discounted Price</h5>
                          </Col>
                          <Col>
                            <p style={{ color: "green" }}>
                              ${order.discountedPrice.toFixed(2)}
                            </p>
                          </Col>
                        </Row>
                      )}
                    </ListGroup.Item>
                    {loadingDeliver && <Loader />}
                    {userInfo && userInfo.isAdmin && !order.isDelivered ? (
                      <ListGroup.Item>
                        <Button
                          type="button"
                          className="btn btn-block"
                          onClick={deliverHandler}
                        >
                          Mark As Delivered
                        </Button>
                      </ListGroup.Item>
                    ) : (
                      <></>
                    )}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

export default OrderScreen;
